import { createContext } from 'react';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

export const LiveQuizAdminContext = createContext();

export class LiveQuizAdmin {
  static LOTTARY_TARGETS_UNCELECTED = 'unelected';
  static LOTTARY_TARGETS_ALL = 'all';

  static MODE_SETUP = 'setup';
  static MODE_OFFER = 'offer';
  static MODE_PROCESS = 'process';
  static MODE_ANSWER = 'answer';

  get isAuthorized() {
    return firebase.auth().currentUser ? true : false;
  }

  get email() {
    const user = firebase.auth().currentUser;
    return user ? user.email : '';
  }

  get holdingEventCode() {
    return localStorage.getItem("LiveQuizAdminEventCode");
  }

  get mode() {
    if (!this.isAuthorized || !this.eventRef) {
      return LiveQuizAdmin.MODE_SETUP;
    }

    if (!this.currentQuestionKey) {
      return LiveQuizAdmin.MODE_OFFER;
    }

    if (this.timeLimits > 0) {
      return LiveQuizAdmin.MODE_PROCESS;
    }

    return LiveQuizAdmin.MODE_ANSWER;
  }

  get entryCount() {
    return this.entries.length;
  }

  get answerCount() {
    if (!this.currentQuestionKey) {
      return { total: 0, yes: 0, no: 0 };
    }

    const key = this.currentQuestionKey;
    return this.entries.reduce((count, entry) => {
      const answer = entry.answers[key];
      if (answer) {
        count.total++;
        const value = answer.value;
        if (value === "YES") {
          count.yes++;
        }
        if (value === "NO") {
          count.no++;
        }
      }
      return count;
    }, { total: 0, yes: 0, no: 0 });
  }

  get timeLimits() {
    const question = this.currentQuestion;
    if (!question) {
      return 0;
    }

    const status = this.questionStatuses[this.currentQuestionKey];
    const start = new Date(status.startAt);
    const timeLimits = question.timeLimits * 1000 - (Date.now() - start);
    return timeLimits > 0 ? timeLimits : 0;
  }

  get currentQuestion() {
    return this.questions[this.currentQuestionKey];
  }

  async signIn(email, password) {
    const auth = firebase.auth();
    await auth.signInWithEmailAndPassword(email, password);
  }

  async signOut() {
    const auth = firebase.auth();
    await auth.signOut();
  }

  async setup(eventCode, password, capacity, callbacks) {
    if (!this.isAuthorized) {
       throw new Error('User is not authorized');
    }

    if (!eventCode || !password || !capacity) {
      throw new Error('Invalid parameter');
    }

    this._initProperty(eventCode, password, capacity, callbacks);

    const path = `livequiz/events/${this.eventCode}`;
    this.eventRef = firebase.database().ref(path);
    const eventSnapshot =  await this.eventRef.once('value');
    const event = eventSnapshot.val();
    if (event) {
      if (event.endAt) {
        throw new Error('The event is over');
      }
      throw new Error('The event has already been being held');
    }

    await this.eventRef.set({
      password: password,
      capacity: capacity,
      startAt: firebase.database.ServerValue.TIMESTAMP
    });

    await this._initQuestions();
    this._initSubscription(path);

    localStorage.setItem("LiveQuizAdminEventCode", eventCode);
  }

  _initProperty(eventCode, password, capacity, callbacks) {
    this.eventCode = eventCode;
    this.password = password;
    this.capacity = capacity;
    this.questions = {};
    this.entries = [];
    this.currentQuestionKey = null;
    this.questionStatuses = {};
    this.winners = [];
    this.callbacks = callbacks;
  }

  async _initQuestions() {
    const database = firebase.database();
    const questionsRef = database.ref('livequiz/questions');
    const snapshot = await questionsRef.orderByKey().once('value');
    this.questions = snapshot.val();
  }

  _initSubscription(path) {
    const database = firebase.database();
    this.entriesRef = database.ref(`${path}/entries`);
    this.questionsRef = database.ref(`${path}/questions`);
    this.answersRef = database.ref(`${path}/answers`);
    this.lotteriesRef = database.ref(`${path}/lotteries`);

    const users = database.ref('livequiz/users');
    const checkEntry = async (post) => {
      if (this.capacity <= this.entryCount) {
        return "Over capacity";
      }

      if (!post || !post.friendCode || !post.password) {
        return "Invalid parameter";
      }

      if (post.password !== this.password) {
        return "Password doesn't match";
      }

      const friendCode = post.friendCode;
      const snapshot = await users.child(friendCode).once('value');
      if (!snapshot.exists()) {
        return "User doesn't exists";
      }

      if (this.entries.find(entry => entry.code === friendCode)) {
        return "User already has been enteried";
      }

      return null;
    };

    const entry = async (childSnapshot, prevChildKey)=>{
      const key = childSnapshot.key;
      const val = childSnapshot.val();
      const post = val.post;

      if (val.id > 0 || !post) {
        return;
      }

      await navigator.locks.request('entry', async lock => {
        const errorMessage = await checkEntry(post);
        if (!errorMessage) {
          const index = this.entryCount;
          const id = index + 1;
          await this.entriesRef.child(key).update({
            id: id,
            timestamp: firebase.database.ServerValue.TIMESTAMP
          });

          const code = post.friendCode;
          const entry = this._setupEntry(id, key, code);

          this.callbacks.onEntry(entry);  
        } else {
          await this.entriesRef.child(key).update({
            error: errorMessage
          });
        }
      });
    }
    this.entriesRef.on('child_added', entry);
  }

  _setupEntry(id, key, code) {
    const index = id - 1;
    const entry = this._createEntry(id, key, code);
    this.entries[index] = entry;

    const answers = (childSnapshot, prevChildKey)=>{
      const key = childSnapshot.key;
      const val = childSnapshot.val();
      if (val) {
        entry.answers[key] = val;
        this.callbacks.onAnswer(entry);
      }
    }
    this.entriesRef.child(`${key}/post/answers`).on('child_added', answers);

    return entry;
  }

  _createEntry(id, key, code) {
    return {
      id: id,
      key: key,
      code: code,
      answers: {},
      score: 0,
      ranking: 0,
      lotteryScore: 0,
      win: [],
      elected: false
    };
  }

  async question(key) {
    if (this.currentQuestionKey) {
      throw new Error('In question');
    }

    const question = this.questions[key];
    const value = {
      source: question.source,
      content: question.content,
      point: question.point,
      timeLimits: question.timeLimits,
      timestamp: firebase.database.ServerValue.TIMESTAMP
    };
    if (question.contentImage) {
      value.contentImage = question.contentImage;
    }
    await this.questionsRef.child(key).set(value);

    this.questionStatuses[key] = { key: key, startAt: Date.now(), endAt: null };
    this.currentQuestionKey = key;
  }

  async answer(value) {
    if (!this.currentQuestionKey) {
      throw new Error('No question');
    }

    const key = this.currentQuestionKey;
    const point = this.currentQuestion.point;
    this.entries.forEach(entry => {
      const answer = entry.answers[key];
      if (answer) {
        const correct = answer.value === value;
        if (correct) {
          entry.score += point;
        }
      }
    });

    let rankings = this.entries.slice();
    rankings.sort((a, b)=> b.score - a.score);

    let ranking = 0;
    rankings = rankings.map((entry, index, array)=>{
      if (index > 0 && array[index-1].score > entry.score) {
        ranking++;
      }

      entry.ranking = ranking + 1;
      const coefs = [ 50, 20, 10, 5 ];
      const coef = ranking < coefs.length ? coefs[ranking] : 1;
      entry.lotteryScore = (entry.score + 1) * coef;

      return {
        id: entry.id,
        score: entry.score,
        ranking: entry.ranking
      };
    });

    await this.answersRef.child(key).set({
      answer: value,
      rankings: rankings,
      timestamp: firebase.database.ServerValue.TIMESTAMP
    });

    this.questionStatuses[key].endAt = Date.now();
    this.currentQuestionKey = null;
  }

  async lot(lottaryTargets) {
    let targets;
    if (LiveQuizAdmin.LOTTARY_TARGETS_UNCELECTED === lottaryTargets) {
      targets = this.entries.filter(entry => entry.elected === false);
    } else {
      targets = this.entries;
    }

    const winner = this._resolveWinner(targets);
    if (!winner) {
      return null;
    }

    const lotteryRef = this.lotteriesRef.push();
    await lotteryRef.set({
      winnerId: winner.id,
      winnerCode: winner.code,
      targets: lottaryTargets,
      timestamp: firebase.database.ServerValue.TIMESTAMP
    });

    const winningNumber = this._createWinningNumber();
    winner.win.push(winningNumber);
    if (LiveQuizAdmin.LOTTARY_TARGETS_UNCELECTED === lottaryTargets) {
      winner.elected = true;
    }

    const lotteryKey = lotteryRef.key;
    const path = `${winner.key}/winningNumbers/${lotteryKey}`;
    await this.entriesRef.child(path).set({
      winningNumber: winningNumber,
      timestamp: firebase.database.ServerValue.TIMESTAMP
    });

    const lottery = {
      key: lotteryKey,
      id: winner.id,
      code: winner.code,
      winningNumber: winningNumber,
      freebieNumber: '',
      timestamp: Date.now()
    };

    this.winners.unshift(lottery);

    return lottery;
  }

  _resolveWinner(targets) {
    const totalLotteryScore = targets.reduce((acc, entry) => {
      return acc + entry.lotteryScore;
    }, 0);

    if (totalLotteryScore === 0) {
      return undefined;
    }

    let lottery = Math.floor(Math.random() * totalLotteryScore);
    return targets.find(entry => {
      lottery -= entry.lotteryScore;
      return lottery < 0;
    });
  }

  _createWinningNumber() {
    return Array(8).fill()
                   .map(e => Math.floor(Math.random() * 10))
                   .join('');
  }

  async saveFreebieNumber(key, freebieNumber) {
    await this.lotteriesRef.child(key).update({
      freebieNumber: freebieNumber
    });
  }

  async teardown() {
    if (!this.eventRef) {
      throw new Error('No events are being held');
    }

    this.entries.forEach(entry => {
      this.entriesRef.child(`${entry.key}/post/answers`).off();
    });
    this.entriesRef.off();
    await this.eventRef.update({
      endAt: firebase.database.ServerValue.TIMESTAMP
    });
    this.eventRef = null;
    this.discard();
  }

  async restore(eventCode, callbacks) {
    if (!this.isAuthorized) {
       throw new Error('User is not authorized');
    }

    if (!eventCode) {
      throw new Error('Invalid parameter');
    }

    this._initProperty(eventCode, null, 0, callbacks);

    const database = firebase.database();

    const path = `livequiz/events/${this.eventCode}`;
    this.eventRef = database.ref(path);
    const eventSnapshot =  await this.eventRef.once('value');
    const event = eventSnapshot.val();
    if (!event) {
      throw new Error("The event has not been held");
    }
    if (event.endAt) {
      throw new Error('The event is over');
    }

    this.password = event.password;
    this.capacity = event.capacity;

    if (event.entries) {
      this.entriesRef = database.ref(`${path}/entries`);
      for (let key of Object.keys(event.entries)) {
        const entry = event.entries[key];
        const id = entry.id;
        if (id > 0) {
          this._setupEntry(id, key, entry.post.friendCode);
        } else {
          await this.entriesRef.child(key).remove();
        }
      }
    }

    if (event.questions) {
      Object.keys(event.questions).forEach((key) => {
        const question = event.questions[key];
        this.questionStatuses[key] = {
          key: key,
          startAt: question.timestamp,
          endAt: null
        };
      });
    }

    if (event.answers) {
      const keys = Object.keys(event.answers);

      const answers = keys.map((key) => {
        return event.answers[key];
      });
      const lastAnswer = answers.sort((a, b) => b.timestamp - a.timestamp)[0];
      lastAnswer.rankings.forEach(data => {
        const index = data.id - 1;
        const entry = this.entries[index];
        if (entry) {
          entry.score = data.score;
          entry.ranking = data.ranking;
        }
      });

      keys.forEach((key) => {
        const answer = event.answers[key];
        this.questionStatuses[key].endAt = answer.timestamp;
      });
    }

    const keys = Object.keys(this.questionStatuses);
    if (0 < keys.length) {
      const questions = keys.map((key) => {
        return this.questionStatuses[key];
      });
      const lastQuestion = questions.sort((a, b) => b.startAt - a.startAt)[0];
      if (!lastQuestion.endAt) {
        this.currentQuestionKey = lastQuestion.key;
      }
    }

    if (event.lotteries) {
      Object.keys(event.lotteries).forEach((key) => {
        const lottery = event.lotteries[key];
        const id = lottery.winnerId;
        const index = id - 1;
        const entry = this.entries[index];
        const winningNumbers = event.entries[entry.key].winningNumbers;
        this.winners.unshift({
          key: key,
          id: lottery.winnerId,
          code: lottery.winnerCode,
          winningNumber: winningNumbers[key] ? winningNumbers[key].winningNumber : '',
          freebieNumber: lottery.freebieNumber ? lottery.freebieNumber : '',
          timestamp: lottery.timestamp
        });
        if (LiveQuizAdmin.LOTTARY_TARGETS_UNCELECTED === lottery.targets) {
          entry.elected = true;
        }
      });
      this.winners.sort((a, b) => b.timestamp - a.timestamp);
    }

    await this._initQuestions();
    this._initSubscription(path);
  }

  discard() {
    localStorage.removeItem("LiveQuizAdminEventCode");
  }
}