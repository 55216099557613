import React, { Component } from 'react';
import {
  Navbar,
  Container,
  Row,
  Col,
  FormControl,
  InputGroup,
  Button,
  Form,
  Table,
  Modal
} from 'react-bootstrap';
import Moment from 'react-moment';
import Countdown from '../../components/Countdown/Countdown';
import styles from './Main.module.scss';
import { LiveQuizAdminContext, LiveQuizAdmin } from '../../contexts/LiveQuizAdmin';

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: '',
      email: '',
      eventCode: '',
      password: '',
      capacity: 300,
      entryCount: 0,
      answerCount: { total: 0, yes: 0, no: 0 },
      options: [],
      lottaryTargets: 'unelected',
      winners: []
    };

    this.questionsRef = React.createRef();
    this.countdownRef = React.createRef();
  }

  get LiveQuizAdmin() {
    return this.context;
  }

  componentDidMount() {
    if (!this.LiveQuizAdmin.isAuthorized) {
      return;
    }

    const eventCode = this.LiveQuizAdmin.holdingEventCode;
    const password = Array(4).fill()
                             .map(e => Math.floor(Math.random() * 10))
                             .join('');
    this.setState({
      mode: this.LiveQuizAdmin.mode,
      email: this.LiveQuizAdmin.email,
      eventCode: eventCode ? eventCode : '',
      password: password,
      capacity: 300,
      entryCount: 0,
      answerCount: { total: 0, yes: 0, no: 0 },
      options: [],
      lottaryTargets: 'unelected',
      winners: []
    });

    if (eventCode) {
      this.showMessage('開催',
        `イベント[${eventCode}]が開始されたままです\n再開しますか？`,
        {
          yes: async () => {
            await this.restore();
          },
          no: async () => {
            this.LiveQuizAdmin.discard();
          }
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const questions = this.questionsRef.current;
    const length = questions.options.length;
    for (let i=0; i < length; i++) {
      const option = questions.options[i];
      const key = option.value;
      const questionStatus = this.LiveQuizAdmin.questionStatuses[key];
      if (questionStatus && questionStatus.startAt) {
        option.setAttribute("disabled", "true");
      } else {
        option.removeAttribute("disabled");
      }
    }

    const mode = this.LiveQuizAdmin.mode;
    if (mode === LiveQuizAdmin.MODE_OFFER) {
      for (let i=0; i < length; i++) {
        const option = questions.options[i];
        if (!option.hasAttribute("disabled")) {
          questions.selectedIndex = i;
          break;
        }
      }
    } else if (mode === LiveQuizAdmin.MODE_PROCESS
            || mode === LiveQuizAdmin.MODE_ANSWER) {
      const currentQuestionKey = this.LiveQuizAdmin.currentQuestionKey;
      for (let i=0; i < length; i++) {
        const option = questions.options[i];
        if (option.value === currentQuestionKey) {
          questions.selectedIndex = i;
          break;
        }
      }
    }
  }

  recieveEntry(entry) {
    this.setState({ entryCount: this.LiveQuizAdmin.entryCount });
  }

  recieveAnswer(entry) {
    this.setState({ answerCount: this.LiveQuizAdmin.answerCount });
  }

  changeInput(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  async signOut() {
    try {
      await this.LiveQuizAdmin.signOut();
      this.props.history.goBack();
    } catch (err) {
      console.log(err);
      this.setState({
        mode: this.LiveQuizAdmin.mode,
        dialog: {
          title: 'サインアウト',
          message: 'サインアウトできませんでした'
        }
      });
    }
  }

  async setup() {
    try {
      await this.LiveQuizAdmin.setup(
        this.state.eventCode,
        this.state.password,
        this.state.capacity,
        {
          onEntry: (entry)=>{
            this.recieveEntry(entry);
          },
          onAnswer: (entry)=>{
            this.recieveAnswer(entry);
          }
        }
      );
  
      const options = [];
      for (let key of Object.keys(this.LiveQuizAdmin.questions)) {
        const question = this.LiveQuizAdmin.questions[key];
        const {source, content, point, timeLimits} = question;
        options.push({
          key: key,
          content: `${key} [${timeLimits}s][${point}p][${source}] : ${content}`
        });
      }

      this.setState({
        mode: this.LiveQuizAdmin.mode,
        options: options,
        entryCount: 0,
        answerCount: { total: 0, yes: 0, no: 0 },
        winners: [],
        dialog: {
          title: '開催',
          message: 'イベントを開始しました'
        }
      });
    } catch (err) {
      if (err.message === 'The event has already been being held') {
        this.showMessage('開催',
          '既にイベントは開始されています。\n再開しますか？',
          {
            yes: async () => {
              await this.restore();
            },
            no: async () => {}
          }
        );
      } else {
        this.showMessage('開催',
          this.getErrorMessage('イベントを開始できませんでした', err)
        );
      }
    }
  }

  requestTeardown() {
    this.showMessage('終了',
      'イベントを終了してもよろしいですか？',
      {
        yes: async () => {
          await this.teardown();
        },
        no: async () => {}
      }
    );
  }

  async teardown() {
    try {
      await this.LiveQuizAdmin.teardown();

      this.setState({
        mode: this.LiveQuizAdmin.mode,
        dialog: {
          title: '開催',
          message: 'イベントを終了しました'
        }
      });  
    } catch (err) {
      this.showMessage('開催',
        this.getErrorMessage('イベントを終了できませんでした', err)
      );
    }
  }

  async restore() {
    try {
      await this.LiveQuizAdmin.restore(
        this.state.eventCode,
        {
          onEntry: (entry)=>{
            this.recieveEntry(entry);
          },
          onAnswer: (entry)=>{
            this.recieveAnswer(entry);
          }
        }
      );

      const options = [];
      for (let key of Object.keys(this.LiveQuizAdmin.questions)) {
        const question = this.LiveQuizAdmin.questions[key];
        const {source, content, point, timeLimits} = question;
        options.push({
          key: key,
          content: `${key} [${timeLimits}s][${point}p][${source}] : ${content}`
        });
      }

      const currentQuestion = this.LiveQuizAdmin.currentQuestion;

      const timeLimits = this.LiveQuizAdmin.timeLimits;
      if (timeLimits > 0) {
        this.countdownRef.current.start(timeLimits);
      }

      const winners =this.LiveQuizAdmin.winners.map((winner) => {
        return Object.assign({
          freebieNumberInput: winner.freebieNumber
        }, winner);
      });

      this.setState({
        mode: this.LiveQuizAdmin.mode,
        eventCode: this.LiveQuizAdmin.eventCode,
        password: this.LiveQuizAdmin.password,
        capacity: this.LiveQuizAdmin.capacity,
        options: options,
        entryCount: this.LiveQuizAdmin.entryCount,
        answerCount: this.LiveQuizAdmin.answerCount,
        time: Math.floor(timeLimits / 1000),
        answer: currentQuestion ? currentQuestion.answer : '',
        winners: winners,
        dialog: {
          title: '開催',
          message: 'イベントを再開しました'
        }
      });
    } catch (err) {
      this.showMessage('開催',
        this.getErrorMessage('イベントを再開できませんでした', err)
      );
    }
  }

  async question() {
    try {
      const questions = this.questionsRef.current;
      const index = questions.selectedIndex;
      const option = questions.options[index];
      const key = option.value;
      await this.LiveQuizAdmin.question(key);

      const question = this.LiveQuizAdmin.currentQuestion;
      const timeLimits = this.LiveQuizAdmin.timeLimits;
      this.setState({
        mode: this.LiveQuizAdmin.mode,
        answer: question.answer,
        answerCount: { total: 0, yes: 0, no: 0 },
        time: Math.floor(timeLimits / 1000),
      });
  
      this.countdownRef.current.start(timeLimits);
    } catch (err) {
      this.showMessage('出題',
        this.getErrorMessage('出題を送信できませんでした', err)
      );
    }
  }

  updateTime(time) {
    this.setState({ time: time });
  }

  timeout() {
    this.setState({ mode: this.LiveQuizAdmin.mode });
  }

  async answer(value) {
    try {
      await this.LiveQuizAdmin.answer(value);
      this.setState({ mode: this.LiveQuizAdmin.mode });
    } catch (err) {
      this.showMessage('正解',
        this.getErrorMessage('正解を送信できませんでした', err)
      );
    }
  }

  async lot() {
    try {
      const lottaryTargets = this.state.lottaryTargets;
      const winner = await this.LiveQuizAdmin.lot(lottaryTargets);
      if (winner) {
        const winners = this.state.winners.slice();
        winners.unshift(Object.assign({
          freebieNumberInput: winner.freebieNumber
        }, winner));
        this.setState({winners: winners});
      } else {
        this.showMessage('抽選', '当選者を決定できませんでした');
      }
    } catch (err) {
      this.showMessage('抽選',
        this.getErrorMessage('抽選できませんでした', err)
      );
    }
  }

  changeFreebieNumberInput(winner, event) {
    winner.freebieNumberInput = event.target.value;
    this.setState({winners: this.state.winners.slice()});
  }

  async saveFreebieNumber(winner) {
    try {
      winner.freebieNumber = winner.freebieNumberInput;
      await this.LiveQuizAdmin.saveFreebieNumber(
        winner.key, winner.freebieNumber
      );
      this.showMessage('当選', '景品番号を保存しました');
    } catch (err) {
      this.showMessage('当選',
        this.getErrorMessage('景品番号を保存できませんでした', err)
      );
    }
  }

  getErrorMessage(message, error) {
    console.error(error);
    return `${message}\n\n${error}`;
  }

  showMessage(title, message, header = null) {
    this.setState({
      dialog: {
        title: title,
        message: `${message}`,
        header: header
      }
    });
  }

  hideDialog() {
    this.setState({ dialog: null });
  }

  render() {
    if (!this.LiveQuizAdmin.isAuthorized) {
      this.props.history.goBack();
      return null;
    }

    const {
      mode,
      email,
      eventCode,
      password,
      capacity,
      entryCount,
      answerCount,
      options,
      time,
      lottaryTargets,
      winners,
      answer,
      dialog
    } = this.state;

    const canOffer = mode === LiveQuizAdmin.MODE_OFFER && entryCount > 0;
    const showDialog = dialog ? true : false;
    const dialogTitle = dialog ? dialog.title : '';
    const dialogMessage = dialog ? dialog.message : '';
    const dialogHeader = dialog ? dialog.header : null;

    return (<>
      <Navbar className="justify-content-between shadow-sm" sticky="top" bg="app-color" variant="dark">
        <Navbar.Brand>実況！マグパー英語クイズ出題アプリ</Navbar.Brand>
        <div>
          <span className={`text-light ml-3 mr-3`}>{email}</span>
          <Button variant="outline-light"
            onClick={() => this.signOut()}
          >SIGN OUT</Button>
        </div>
      </Navbar>
      <Container className="pt-3">
        <h4 className="mb-3">開催</h4>
        <Row className="mb-3">
          <Col>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="btnEventCode">イベントコード</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="text"
                aria-label="イベントコード"
                aria-describedby="btnEventCode"
                name="eventCode"
                value={eventCode}
                onChange={this.changeInput.bind(this)}
                disabled={mode !== LiveQuizAdmin.MODE_SETUP}
              />
            </InputGroup>
          </Col>
          <Col md={3}>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="btnPassword">秘密の番号</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="text"
                placeholder="数字"
                aria-label="秘密の番号"
                aria-describedby="btnPassword"
                name="password"
                value={password}
                onChange={this.changeInput.bind(this)}
                disabled={mode !== LiveQuizAdmin.MODE_SETUP}
              />
            </InputGroup>
          </Col>
          <Col md={2}>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="btnCapacity">定員</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="text"
                placeholder="数字"
                aria-label="定員"
                name="capacity"
                value={capacity}
                aria-describedby="btnCapacity"
                onChange={this.changeInput.bind(this)}
                disabled={mode !== LiveQuizAdmin.MODE_SETUP}
              />
            </InputGroup>
          </Col>
          <Col md={2}>
            <Button
              className="w-100"
              onClick={() => this.setup()}
              disabled={mode !== LiveQuizAdmin.MODE_SETUP}
            >開始</Button>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>
            <div className={styles.infoGroup}>
              <div className={styles.prepend}>参加人数</div>
              <div className={styles.info}>{entryCount}</div>
            </div>
          </Col>
          <Col md={2}>
            <Button
              className="w-100"
              onClick={() => this.requestTeardown()}
              disabled={mode !== LiveQuizAdmin.MODE_OFFER}
            >終了</Button>
          </Col>
        </Row>
        <hr className="mb-3" />

        <h4 className={`mb-3 ${canOffer ? '' : styles.modeDisabled}`}>出題</h4>
        <Row className="mb-3">
          <Col>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="btnEventCode">問題</InputGroup.Text>
              </InputGroup.Prepend>
              <select className="custom-select d-block"
                ref={this.questionsRef}
                disabled={!canOffer}
              >
              {options.map((o) => {
                return (
                  <option key={o.key} value={o.key}>{o.content}</option>
                );
              })}
              </select>
            </InputGroup>
          </Col>
          <Col md={2}>
            <Button
              className="w-100"
              onClick={() => this.question()}
              disabled={!canOffer}
            >実行</Button>
          </Col>
        </Row>
        <hr className="mb-3" />

        <h4 className={`mb-3 ${mode === LiveQuizAdmin.MODE_PROCESS ? '' : styles.modeDisabled}`}>経過</h4>
        <Row className="mb-3 align-items-center">
          <Col md={1} className="text-center"><div className={styles.time}>{time}秒</div></Col>
          <Col>
            <Countdown
              ref={this.countdownRef}
              update={(time) => this.updateTime(time)}
              finish={() => this.timeout()}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>
            <div className={styles.infoGroup}>
              <div className={styles.prepend}>回答数</div>
              <div className={styles.info}>{answerCount.total}</div>
            </div>
          </Col>
          <Col md={3}>
            <div className={styles.infoGroup}>
              <div className={styles.prepend}>YES 回答数</div>
              <div className={styles.info}>{answerCount.yes}</div>
            </div>
          </Col>
          <Col md={3}>
            <div className={styles.infoGroup}>
              <div className={styles.prepend}>NO 回答数</div>
              <div className={styles.info}>{answerCount.no}</div>
            </div>
          </Col>
        </Row>
        <hr className="mb-3" />

        <h4 className={`mb-3 ${mode === LiveQuizAdmin.MODE_ANSWER ? '' : styles.modeDisabled}`}>正解</h4>
        <Row className="mb-3 justify-content-between">
          <Col md={3}>
            <div className={styles.infoGroup}>
              <div className={styles.prepend}>正解</div>
              <div className={styles.info}>{answer}</div>
            </div>
          </Col>
          <Col md={8}>
            <Row className="justify-content-between">
              <Col>
                <Button
                  className="w-100"
                  onClick={() => this.answer('YES')}
                  disabled={!(mode === LiveQuizAdmin.MODE_ANSWER && answer !== 'NO')}
                >YES</Button>
              </Col>
              <Col>
                <Button
                  className="w-100"
                  onClick={() => this.answer('NO')}
                  disabled={!(mode === LiveQuizAdmin.MODE_ANSWER && answer !== 'YES')}
                >NO</Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className="mb-3" />

        <h4 className={`mb-3 ${canOffer ? '' : styles.modeDisabled}`}>抽選</h4>
        <Row className="mb-3">
          <Col>
            <div className={styles.infoGroup}>
              <div className={styles.prepend}>対象</div>
              <div className={`${styles.info} justify-content-around`}>
                <Form.Check
                  id="lottaryTargetsUnelected"
                  custom
                  inline
                  type="radio"
                  name="lottaryTargets"
                  value={LiveQuizAdmin.LOTTARY_TARGETS_UNCELECTED}
                  label="未当選"
                  checked={lottaryTargets === LiveQuizAdmin.LOTTARY_TARGETS_UNCELECTED}
                  onChange={this.changeInput.bind(this)}
                />
                <Form.Check
                  id="lottaryTargetsAll"
                  custom
                  inline
                  type="radio"
                  name="lottaryTargets"
                  value={LiveQuizAdmin.LOTTARY_TARGETS_ALL}
                  label="全員"
                  checked={lottaryTargets === LiveQuizAdmin.LOTTARY_TARGETS_ALL}
                  onChange={this.changeInput.bind(this)}
                />
              </div>
            </div>
          </Col>
          <Col md={{span:2, offset: 6}}>
            <Button
              className="w-100"
              onClick={() => this.lot()}
              disabled={!canOffer}
            >実行</Button>
          </Col>
        </Row>
        <hr className="mb-3" />

        <h4 className={`mb-3 ${winners.length > 0 ? '' : styles.modeDisabled}`}>当選</h4>
        <Row className="mb-3">
          <Col md={3}>
            <div className={styles.infoGroup}>
              <div className={styles.prepend}>当選人数</div>
              <div className={styles.info}>{winners.length}</div>
            </div>
          </Col>
        </Row>
        <Row className="mb-3 pl-3 pr-3">
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th key="th-winner-gift-timestamp">時刻</th>
                <th key="th-winner-entry-no">NO</th>
                <th key="th-winner-friend-code">フレンドコード</th>
                <th key="th-winner-winning-number">当選番号</th>
                <th key="th-winner-gift-no">景品番号</th>
              </tr>
            </thead>
            <tbody>
            {winners.map((winner) => (
              <tr key={`tr-${winner.timestamp}`}>
                <td><Moment format="HH:mm:ss">{new Date(winner.timestamp).toISOString()}</Moment></td>
                <td>{winner.id}</td>
                <td>{winner.code}</td>
                <td>{winner.winningNumber}</td>
                <td>
                  <InputGroup size="sm">
                    <FormControl
                      type="text"
                      value={winner.freebieNumberInput}
                      onChange={(event) => this.changeFreebieNumberInput(winner, event)}
                    />
                    <InputGroup.Append>
                      <Button
                        variant="outline-secondary"
                        onClick={() => this.saveFreebieNumber(winner) }
                        disabled={winner.freebieNumber === winner.freebieNumberInput}
                      ><i className={`material-icons ${styles.icon}`}>save</i></Button>
                    </InputGroup.Append>
                  </InputGroup>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        </Row>
      </Container>
      <Modal
        show={showDialog}
        onHide={() => this.hideDialog()}
        animation={false}
      >
        <Modal.Header closeButton>
            <Modal.Title>{dialogTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{
          dialogMessage.split('\n').map((str, index) => (
            <React.Fragment key={index}>{str}<br /></React.Fragment>
          ))
        }</Modal.Body>
        <Modal.Footer>
        {dialogHeader ?
          <>
            <Button onClick={async () => {
              this.hideDialog();
              await dialogHeader.yes();
            }}>はい</Button>
            <Button onClick={async () => {
              this.hideDialog();
              await dialogHeader.no();
            }}>いいえ</Button>
          </> :
          <Button onClick={() => this.hideDialog()}>閉じる</Button>
        }
        </Modal.Footer>
      </Modal>
    </>);
  }
}

Main.contextType = LiveQuizAdminContext;
export default Main;