import React, { Component } from 'react';
import {
  Image,
  Form,
  Button,
  Modal
} from 'react-bootstrap';
import styles from './Signin.module.scss';
import { LiveQuizAdminContext } from '../../contexts/LiveQuizAdmin';

class Signin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: ''
    };
  }

  get LiveQuizAdmin() {
    return this.context;
  }

  changeInput(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  async signIn() {
    try {
      await this.LiveQuizAdmin.signIn(
        this.state.email,
        this.state.password
      );
      this.props.history.push({ pathname: '/' });
    } catch(err) {
      this.showMessage('SIGN IN', 'サインインできませんでした', err);
    }
  }

  showMessage(title, message, error) {
    if (error) {
      console.error(error);
      message = `${message}\n\n${error}`;
    }
    this.setState({
      dialog: {
        title: title,
        message: `${message}`
      }
    });
  }

  hideDialog() {
    this.setState({ dialog: null });
  }

  render() {
    const {
      email,
      password,
      dialog
    } = this.state;

    const showDialog = dialog ? true : false;
    const dialogTitle = dialog ? dialog.title : '';
    const dialogMessage = dialog ? dialog.message : '';

    return (<>
      <Form className={styles.form}>
        <div className="text-center mb-4">
          <Image src="logo.png" alt="実況！マグパー英語クイズ出題アプリ" width="256" height="256" />
        </div>
        <Form.Group className="mb-4">
          <Form.Control type="email" placeholder="メールアドレス"
            name="email"
            value={email}
            onChange={this.changeInput.bind(this)}
          />
        </Form.Group>
        <Form.Group className="mb-5">
          <Form.Control type="password" placeholder="パスワード"
            name="password"
            value={password}
            onChange={this.changeInput.bind(this)}          
          />
        </Form.Group>
        <Button block
          onClick={() => this.signIn()}
        >SIGN IN</Button>
      </Form>
      <p className="mt-5 mb-3 text-muted text-center">© MINTFLAG Inc.  All Rights Reserved.</p>
      <Modal
        show={showDialog}
        onHide={() => this.hideDialog()}
        animation={false}
      >
        <Modal.Header closeButton>
            <Modal.Title>{dialogTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{
          dialogMessage.split('\n').map((str, index) => (
            <React.Fragment key={index}>{str}<br /></React.Fragment>
          ))
        }</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => this.hideDialog()}>
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>
    </>);
  }
}

Signin.contextType = LiveQuizAdminContext;
export default Signin;