import React from 'react';
import {
  Route,
  Redirect,
  Switch,
  BrowserRouter as Router
} from "react-router-dom";
import Signin from './pages/Signin/Signin';
import Main from './pages/Main/Main';
import { LiveQuizAdminContext, LiveQuizAdmin } from './contexts/LiveQuizAdmin';

function Auth(props) {
  return (
    <LiveQuizAdminContext.Consumer>
      {liveQuizAdmin => {
        if (liveQuizAdmin.isAuthorized) {
          return props.children;
        }
        return (<Redirect from="*" to="/signin" />);
      }}
    </LiveQuizAdminContext.Consumer>
  );
}

function App() {
  return (
    <LiveQuizAdminContext.Provider value={new LiveQuizAdmin()}>
      <Router>
        <Switch>
          <Route exact path="/signin" component={Signin} />
          <Auth>
            <Route exact path="/" component={Main} />
          </Auth>
        </Switch>
      </Router>
    </LiveQuizAdminContext.Provider>
  );
}

export default App;
